<template>
  <section class="not-found-section">
    <h4>{{ $t('message.notfound_title') }}</h4>
  </section>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {};
  },
  computed: {},
  mounted() {
    if (!window.location.hash.includes('#/notfound?path=')) {
      let path = window.location.hash.split('#/')[1];
      this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'NotFound' && to.path !== '/notfound') {
        let path = to.path.split('/')[1];
        this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
      }
    },
  },
  methods: {},
  components: {},
};
</script>
